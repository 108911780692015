import Vue from "vue";
import VueRouter from 'vue-router';

// 集成VueRouter
Vue.use(VueRouter);

// 导入子组件
import Login from '@/components/Login.vue'
import PersonalCenter from '@/components/PersonalCenter.vue'
import Mavon from '@/components/Mavon.vue'
import topShow from '@/components/topShow.vue'
import ArticleDetail from '@/components/ArticleDetail.vue'
import ArticlesList from '@/components/ArticlesList.vue'
import Learn from '@/components/Learn.vue'
import Essay from '@/components/Essay.vue'
import Collection from '@/components/Collection.vue'
import Travel from '@/components/Travel.vue'
import NotFound from '@/components/NotFound.vue'
// 手机端页面
import iArticleList from '@/ipages/iArticleList.vue'
import iArticleDetail from '@/ipages/iArticleDetail.vue'
import iLogin from '@/ipages/iLogin.vue'
import iPersonalCenter from '@/ipages/iPersonalCenter.vue'
import iManageCoins from '@/ipages/iManageCoins.vue'
import iEnterCoin from '@/ipages/iEnterCoin.vue'
import iCoinDetail from '@/ipages/iCoinDetail.vue'

// 实例化路由
const router = new VueRouter({
    module: 'hash',
    routes: [
        {path: '/', component: topShow},
        {path: '/login', component: Login},
        {path: '/personal', component: PersonalCenter},
        {path: '/mavon', component: Mavon},
        {path: '/topshow', component: topShow},
        {path: '/article/:id', component: ArticleDetail},
        {path: '/articles', component: ArticlesList},
        {path: '/learn', component: Learn},
        {path: '/essay', component: Essay},
        {path: '/collection', component: Collection},
        {path: '/travel', component: Travel},
        // 手机端页面
        {path: '/i/articles', component: iArticleList},
        {path: '/i/article/:id', component: iArticleDetail},
        {path: '/i/login', component: iLogin},
        {path: '/i/personal', component: iPersonalCenter},
        {path: '/i/manage_coins', component: iManageCoins},
        {path: '/i/enter_coin', component: iEnterCoin},
        {path: '/i/coin/:id', component: iCoinDetail},

        {path: '*', component: NotFound},
    ]
})

// 暴露router
export default router
