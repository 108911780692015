<template>
    <div id="learn">
        <h3>类别：学习</h3>
        <articles-list></articles-list>
    </div>
</template>

<script>
import ArticlesList from './ArticlesList.vue'

export default {
    components: {
        ArticlesList
    }
}
</script>

<style>

</style>