<template>
    <div id="i-arti-detail">
        <div class="title distance">
            <span class="note">标题：</span>
            {{article_info.title}}
        </div>
        <div class="desc distance">
            <span class="note">描述：</span>
            {{article_info.desc}}
        </div>
        <mavon-editor class="content" v-html="article_info.content"></mavon-editor>
        <!-- 增加评论 -->
        <comment class="comment" v-if="article_info.allow_comment"></comment>
    </div>
</template>

<script>
import marked from 'marked'
import comment from '@/components/comment.vue'

export default {
    components: {
      comment
    },
    data(){
        return {
            article_info: ''
        }
    },
    mounted(){
        this.getArticle(this.$route.params.id);
    },
    methods: {
        async getArticle(id) {
            const res = await this.$http.get(`articles/detail/?id=${id}`);
            if(res.data.code !== 200){
                alert(res.data.msg);
            }else{
                this.article_info = res.data.article_info;
                this.article_info.content = marked(this.article_info.content);
            }
        },
    }
}
</script>

<style>
#i-arti-detail {
    font-size: 14px;
}
#i-arti-detail .content {
    font-size: 12px;
    padding: 5px 5px;
}
#i-arti-detail .content img {
    max-width: 320px;
}
#i-arti-detail .note {
    font-weight: bolder;
}
#i-arti-detail .distance {
    margin-bottom: 10px;
}
/* 父组件中修改子组件样式 */
#i-arti-detail .comment .show .items .reply-oneth {
    max-width: 350px;
    left: 0px;
}
#i-arti-detail .comment .show .items .reply-oneth .reply {
    max-width: 320px;
}
#i-arti-detail .comment .show .reply-wrap .submit-reply {
    left: 0px;
    max-width: 320px;
}
#i-arti-detail .comment .show .reply-wrap .submit-reply .reply {
    max-width: 300px;
}
</style>
