<template>
    <div id="enter-coin">
        <h3 id="title">钱币录入界面</h3>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
            <el-form-item label="名称" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" v-model="ruleForm.remark" placeholder="请输入钱币版别,直径,厚度,克重等">
                </el-input>
            </el-form-item>
            <el-form-item prop="buy_time" label="购买日期">
                <el-date-picker placeholder="选择购买日期" v-model="ruleForm.buy_time"
                                style="width: 100%;">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="购买价格" prop="buy_cost">
                <el-input v-model.number="ruleForm.buy_cost" placeholder="请输入购买价格"></el-input>
            </el-form-item>
            <el-form-item label="卖家" prop="seller">
                <el-input v-model="ruleForm.seller"></el-input>
            </el-form-item>
            <el-form-item label="是否售出" prop="delivery">
                <el-switch v-model="ruleForm.is_sold"></el-switch>
            </el-form-item>
            <el-form-item label="买家" prop="buyer">
                <el-input v-model="ruleForm.buyer"></el-input>
            </el-form-item>
            <el-form-item prop="sold_time" label="售出日期">
                <el-date-picker placeholder="选择售出日期" v-model="ruleForm.sold_time"
                                style="width: 100%;">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="售出价格" prop="sold_money">
                <el-input v-model.number="ruleForm.sold_money" placeholder="请输入售出价格"></el-input>
            </el-form-item>
        </el-form>
        <!--上传图片，限制上传6张-->
        <el-upload
                class="upload-img"
                action="http://127.0.0.1:8000/articles/upload_img/"
                :file-list="img_list"
                :limit="6"
                :on-exceed="handleExceed"
                :on-success="handleSuccess"
                list-type="picture">
            <el-button size="small" type="primary">上传藏品图片</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M，并且每次提交只能上传6张图片</div>
        </el-upload>
        <!-- 提交录入 -->
        <el-button type="primary" id="submit" @click="submitEdition()">提交</el-button>
        <!-- 返回按钮 -->
        <div class="iconfont icon-fanhui" @click="$router.back(-1);"></div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data() {
            var checkCost = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('价格不能为空'));
                }
                setTimeout(() => {
                    if (!Number.isInteger(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 1000);
            };
            return {
                ruleForm: {
                    name: '',
                    remark: '',
                    buy_time: '',
                    buy_cost: '',
                    is_sold: false,
                    sold_time: '',
                    sold_money: '',
                    seller: '',
                    buyer: ''
                },
                // 最多只允许六张图片
                img_list: [],
                // 用于写入数据库的图片链接，不带协议/域名
                db_img_list: [],
                rules: {
                    name: [
                        { required: true, message: '请输入钱币名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    seller: [
                        { required: true, message: '请输入卖家名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    remark: [
                        { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }
                    ],
                    buy_time: [
                        { type: 'date', required: true, message: '请选择购买日期', trigger: 'change' }
                    ],
                    buy_cost: [
                        { required: true, validator: checkCost, trigger: 'blur' }
                    ],
                    sold_money: [
                        { validator: checkCost, trigger: 'blur' }
                    ],
                    buyer: [
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                }
            }
        },
        mounted() {
            if (this.$route.query.coin_id) {
                this.getCoinDetail();
            } else {
                console.log(this.$route.query);
            }
        },
        methods: {
            open1() {
                this.$notify({
                    title: '录入成功',
                    message: '1s后刷新录入界面',
                    type: 'success'
                });
            },
            open2() {
                this.$notify({
                    title: '警告',
                    message: '请先填写必要参数，钱币名称，购买日期，购买价格，卖家必须填写',
                    type: 'warning'
                });
            },
            handleExceed() {
                this.$message.warning(`一件藏品最多只可以上传 6 张图片`);
            },
            handleSuccess(res) {
                this.img_list.push({name: res.name, url: this.$imgUrl + res.path});
                this.db_img_list.push({name: res.name, url: res.path});
            },
            async submitEdition() {
                if (this.ruleForm.name && this.ruleForm.buy_cost && this.ruleForm.buy_time && this.ruleForm.seller) {
                    this.ruleForm.buy_time = moment(this.ruleForm.buy_time).format('YYYY-MM-DD');
                    this.ruleForm.sold_time = moment(this.ruleForm.sold_time).format('YYYY-MM-DD');

                    const res = await this.$http.post('coins/enter_coin/', {
                        'ruleForm': this.ruleForm,
                        'img_list': this.db_img_list
                    });
                    if (res.data.code == 200) {
                        this.open1();
                        setTimeout(() => {
                            location.reload();
                        }, 1000)
                    } else {
                        alert(res.data.msg);
                    }
                } else {
                    this.open2();
                }
            },
            async getCoinDetail() {
                const res = await this.$http.get(`coins/coins_info/${this.$route.query.coin_id}/`);
                const coin_info = res.data.data;
                const db_img_list = coin_info.db_img_list;
                for (var i=0;i < db_img_list.length;i++) {
                    this.img_list.push({name: '图片'+(i+1), url: this.$imgUrl + db_img_list[i]});
                }
                console.log(coin_info);
                this.ruleForm.name = coin_info.name;
                this.ruleForm.remark = coin_info.remark;
                // 将得到的字符串日期转化成date格式
                this.ruleForm.buy_time = Date.parse(coin_info.buy_time);
                this.ruleForm.buy_cost = coin_info.buy_cost;
                if (coin_info.is_sold == "是") {
                    this.ruleForm.is_sold = true;
                } else {
                    this.ruleForm.is_sold = false;
                }
                if (coin_info.sold_time) {
                    this.ruleForm.sold_time = Date.parse(coin_info.sold_time);
                }
                this.ruleForm.sold_money = coin_info.sold_money;
                this.ruleForm.seller = coin_info.seller;
                this.ruleForm.buyer = coin_info.buyer;
            }
        }
    }
</script>

<style>
    #enter-coin #title {
        text-align: center;
        margin-bottom: 20px;
    }
    #enter-coin .upload-img {
        margin-top: 30px;
    }
    #enter-coin #submit {
        margin-top: 20px;
        width: 100%;
    }
    #enter-coin {
        position: relative;
    }
    #enter-coin .icon-fanhui {
        position: absolute;
        right: 20px;
        top: 0;
        font-size: 25px;
    }
</style>