<template>
    <div id="coin-detail">
        <h3 class="title">基本信息</h3>
        <div class="coin-des">
            <label for="">名称</label>
            <span>{{ coin_info.name }}</span>
        </div>
        <div class="coin-des">
            <label for="">备注</label>
            <span>{{ coin_info.remark }}</span>
        </div>
        <div class="coin-des">
            <label for="">购买日期</label>
            <span>{{ coin_info.buy_time }}</span>
        </div>
        <div class="coin-des">
            <label for="">购买价格</label>
            <span>{{ coin_info.buy_cost }}</span>
        </div>
        <div class="coin-des">
            <label for="">卖家</label>
            <span>{{ coin_info.seller }}</span>
        </div>
        <div class="coin-des">
            <label for="">是否售出</label>
            <span>{{ coin_info.is_sold }}</span>
        </div>
        <div class="coin-des">
            <label for="">售出日期</label>
            <span>{{ coin_info.sold_time }}</span>
        </div>
        <div class="coin-des">
            <label for="">售出价格</label>
            <span>{{ coin_info.sold_money }}</span>
        </div>
        <div class="coin-des">
            <label for="">买家</label>
            <span>{{ coin_info.buyer }}</span>
        </div>
        <h3 class="title">钱币图片</h3>
        <a :href="item" v-for="(item, index) in img_list" :key="index">
            <img :src="item" alt="" class="coin-img">
        </a>
        <!-- 删除按钮 -->
        <div class="iconfont icon-shanchu" @click="delCoin()"></div>
        <!-- 修改按钮 -->
        <div class="iconfont icon-15" @click="updateCoin()"></div>
        <!-- 返回按钮 -->
        <div class="iconfont icon-fanhui" @click="$router.back(-1);"></div>
    </div>
</template>

<script>
    export default {
        name: "i-coin-detail",
        data() {
            return {
                coin_info: '',
                img_list: []
            }
        },
        mounted() {
            this.getCoinDetail();
        },
        methods: {
            async getCoinDetail() {
                const res = await this.$http.get(`coins/coins_info/${this.$route.params.id}/`);
                this.coin_info = res.data.data;
                const db_img_list = this.coin_info.db_img_list;
                for (var i=0;i < db_img_list.length;i++) {
                    this.img_list.push(this.$imgUrl + db_img_list[i]);
                }
            },
            delCoin() {
                this.$confirm('是否确定删除此枚钱币？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(async () => {
                    // 选择确定发送删除请求
                    const res = await this.$http.get(`coins/coin_update/${this.$route.params.id}/?choice=delete`);
                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: res.data.msg
                        });
                        //  1s后返回上一级页面
                        setTimeout(() => {
                            this.$router.back(-1);
                        }, 1000)
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.data.msg
                        });
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            updateCoin() {
                if (this.$cookie.get('name') != 'sanbai') {
                    this.$router.push('/i/login');
                } else {
                    this.$router.push(`/i/enter_coin/?coin_id=${this.$route.params.id}`);
                }
            }
        }
    }
</script>

<style>
    #coin-detail .title {
        text-align: center;
    }
    #coin-detail .coin-des {
        margin: 20px 0;
    }
    #coin-detail .coin-des label {
        margin-right: 10px;
        font-size: 16px;
        font-weight: bolder;
        width: 30%;
        text-align: center;
        display: inline-block;
        /* 让文字居于顶部 */
        vertical-align: top;
    }
    #coin-detail .coin-des span {
        width: 65%;
        display: inline-block;
    }
    #coin-detail .coin-img {
        display: block;
        max-width: 300px;
        max-height: 250px;
        margin: 0 auto;
        padding: 10px;
    }
    #coin-detail {
        position: relative;
    }
    #coin-detail .icon-15 {
        position: absolute;
        right: 40px;
        top: 0;
        font-size: 25px;
    }
    #coin-detail .icon-fanhui {
        position: absolute;
        right: 10px;
        top: 0;
        font-size: 25px;
    }
    #coin-detail .icon-shanchu {
        position: absolute;
        right: 70px;
        top: 0;
        font-size: 25px;
    }
</style>