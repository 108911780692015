<template>
    <div class="articles-list">
        <div v-for="item in article_list" :key="item.id" class="article" @click="toArticleDetail(item.id)">
            <div class="title" :title="item.desc">{{item.title}}</div>
            <div class="content-cover">
                <img class="cover" v-if="item.cover_img" :src="item.cover_img" alt="封面图片">
                <div class="content">{{item.content}}</div>
            </div>
            <div class="info">
                <span>{{ item.create_time }}</span>
                <span class="author">{{item.author}}</span>
            </div>
        </div>
        <div class="pg">
            <el-pagination background
                layout="prev, pager, next"
                :page-size="limit"
                :total="total"
                :current-page.sync="page"
                @current-change="currentChange()"
                >
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            limit: 5,
            page: 1,
            total: 0,
            article_list: []
        }
    },
    mounted() {
        this.getArticlesList(this.page, this.limit);
    },
    methods: {
        async getArticlesList(page, limit) {
            const res = await this.$http.get(`articles/list/?category=${this.$route.query.category}&search_data=${this.$route.query.search_data}&page=${page}&limit=${limit}`);
            if (res.data.code === 200){
                this.article_list = res.data.data;
                this.total = res.data.total;
            }else{
                alert(res.data.msg);
            }
        },
        toArticleDetail(article_id){
            this.$router.push(`/article/${article_id}`);
        },
        currentChange() {
            this.getArticlesList(this.page, this.limit);
        }
    }
}
</script>

<style>
.articles-list .article {
    background-color: white;
    padding: 10px 20px;
    margin: 10px 0 20px 0;
    width: 70%;
    border-radius: 10px;
    cursor: pointer;
}
.articles-list .article .title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bolder;
}
.articles-list .article .content-cover {
    display: flex;
}
.articles-list .article .content-cover .cover {
    width: 120px;
    height: 120px;
    margin-right: 20px;
}
.articles-list .article .content-cover .content {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient:vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
}
.articles-list .article .info {
    display: flex;
    justify-content: flex-end;
}
.articles-list .article .info span {
    margin: 5px 10px 5px 0;
    font-size: 14px;
    color: grey;
}
.articles-list .article .info .author {
    color: rgb(255, 161, 130);
}
.articles-list .pg {
    display: flex;
    justify-content: center;
    width: 70%;
}
</style>