<template>
    <div id="personal">
        <h2 id="top-title">功能菜单</h2>
        <div class="top-nav">
            <div class="nav-wrap">
                <div class="iconfont icon-houtaiguanli-fabuwenzhang"></div>
                <div class="nav-title">文章发布</div>
            </div>
            <div class="nav-wrap">
                <div class="iconfont icon-guanliwenzhang"></div>
                <div class="nav-title">文章管理</div>
            </div>
            <div @click="toManageCoins" class="nav-wrap">
                <div class="iconfont icon-qianbi-"></div>
                <div class="nav-title">钱币管理</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                cat: '文章发布'
            }
        },
        methods: {
            toManageCoins(){
                if (this.$cookie.get('name') != 'sanbai') {
                    this.$router.push('/i/login');
                } else {
                    this.$router.push('/i/manage_coins');
                }
            },
        }
    }
</script>

<style>
    #personal #top-title {
        text-align: center;
        margin-bottom: 10px;
    }
    #personal {
        height: 100%;
        min-height: 100vh;
    }
    #personal .top-nav {
        display: flex;
        width: 100%;
        text-align: center;
        flex-wrap: wrap;
    }
    #personal .top-nav .nav-wrap {
        padding: 20px 20px;
        margin-right: 10px;
        cursor: pointer;
        /*min-width: 84px;*/
        /*min-height: 90px;*/
        max-width: 84px;
        max-height: 90px;
    }
    #personal .top-nav .iconfont {
        font-size: 40px;
        margin-bottom: 10px;
    }
    #personal .top-nav div:hover {
        background-color: white;
        border-radius: 10px;
    }
</style>