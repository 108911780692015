<template>
    <div id="login">
        <div class="login-wrap">
            <h4>请持有通行令牌进入私人园地</h4>
            <div class="iconfont icon-icon"></div>
            <div class="solgan">代码改变世界</div>
            <div class="id-pwd">
                <div>
                    <span class="iconfont icon-denglu-yonghu"></span>
                    <input type="text" placeholder="登录用户名" v-model="username">
                </div>
                <div>
                    <span class="iconfont icon-denglu-mima"></span>
                    <input type="password" placeholder="密码" v-model="pwd">
                </div>
                <button @click="login">登录</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            username: '',
            pwd: ''
        }
    },
    methods: {
        async login(){
            const res = await this.$http.post('users/login/', {
                'username': this.username,
                'pwd': this.pwd
            });
            if(res.data.code==200){
                // 跳轉到個人中心
                this.$router.push('/personal');
            }else{
                alert(res.data.msg);
            }
        }
    }
}
</script>

<style>
#login {
    text-align: center;
}
.login-wrap {
    width: 250px;
    height: 300px;
    background-color: white;
    border-radius: 10px;
}
.login-wrap h4 {
    padding: 10px;
}
.login-wrap .icon-icon {
    font-size: 40px;
}
.login-wrap .solgan {
    font-size: 14px;
    color: grey;
}
.login-wrap .id-pwd span {
    margin-right: 5px;
}
.login-wrap .id-pwd input {
    padding: 5px 10px;
}
.login-wrap div {
    margin-bottom: 20px;
}
.login-wrap button {
    padding: 5px 10px;
    cursor: pointer;
}
</style>