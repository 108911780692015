<template>
    <div id="wrap">
        <div id="log">
            <img src="@/assets/img/sanbai.png" alt="sanbai.log" @click="toPersonalCenter()">
        </div>
        <div id="search">
            <el-input class="search-son" v-model="search_data" placeholder="请输入搜索内容"
                      :clearable="search_clearable"></el-input>
            <el-button class="search-son" type="primary" icon="el-icon-search"
                       @click="searchArticles()">搜索</el-button>
        </div>
        <div id="articles">
            <el-timeline>
                <el-timeline-item v-for="(item,index) in data_list" :key="index" :timestamp="item.create_time"
                                  placement="top">
                    <el-card id="article">
                        <h3 @click="toArticleDetail(item.id)">{{ item.title }}</h3>
                        <p>{{ item.desc }}</p>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </div>
        <div id="pg">
            <el-pagination background
                           layout="prev, pager, next, jumper"
                           :page-size="limit"
                           :total="total"
                           :pager-count="page_count"
                           :current-page.sync="page"
                           @current-change="currentChange()"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "i-article-list",
        data() {
            return {
                data_list: [],
                limit: 10,
                total: 1,
                page: 1,
                page_count: 5,
                search_data: '',
                search_clearable: true
            }
        },
        mounted(){
            this.getArticles(this.page, this.limit);
        },
        methods: {
            async getArticles(page, limit){
                const res = await this.$http.get(`articles/list/?page=${page}&limit=${limit}`);
                this.data_list = res.data.data;
                this.total = res.data.total;
            },
            async searchArticles(){
                const res = await this.$http.get(`articles/list/?category=search&search_data=${this.search_data}&page=${this.page}&limit=${this.limit}`);
                this.data_list = res.data.data;
                this.total = res.data.total;
            },
            toArticleDetail(id) {
                this.$router.push(`/i/article/${id}`);
            },
            currentChange() {
                this.getArticles(this.page, this.limit);
            },
            toPersonalCenter() {
                if (this.$cookie.get('name') != 'sanbai') {
                    this.$router.push('/i/login');
                } else {
                    this.$router.push('/i/personal');
                }
            }
        },
    }
</script>

<style scoped>
    #wrap {
        background-color: white;
    }
    #wrap #log img {
        width: 50%;
        height: 50%;
        margin: 5px auto;
        display: block;
    }
    #wrap #pg {
        padding-bottom: 10px;
        display: flex;
        justify-content: center;
    }
    /* 样式穿透 */
    #wrap #pg .el-pagination >>> .el-pagination__jump {
        display: block;
        margin: 8px 0;
    }
    #wrap #search .search-son {
        display: inline-block;
        margin: 10px 10px;
    }
    #wrap #search .el-input {
        width: 60%;
    }
</style>
