<template>
    <div id="mavon">
        <div class="title">
            <span class="left-desc">标题</span><br>
            <el-input v-model="title" placeholder="请输入文章标题"></el-input>
        </div>
        <div class="desc">
            <span class="left-desc">描述</span><br>
            <el-input v-model="desc" placeholder="请输入文章简要描述"></el-input>
        </div>
        <!-- 文章封面图片上传 -->
        <div class="avatar-wrap">
            <span class="left-desc">文章封面</span>
            <el-upload
                class="avatar-uploader"
                action="https://sanbai.org.cn:8000/articles/upload_img/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="cover_img" :src="cover_img" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </div>
        <div class="mavon-editor">
            <span class="left-desc">内容</span><br>
            <mavon-editor ref='md' v-model="content" @imgAdd='$imgAdd'></mavon-editor>
        </div>
        <div class="category">
           <span class="left-desc">分类</span><br>
           <el-radio v-for="(item, index) in cate_list" :key="index" v-model="cate" :label="item" class="cate"></el-radio>
        </div>
        <div class="tags">
            <span class="left-desc">标签</span><br>
            <el-checkbox-group v-model="checkList" class="tags-wrap">
                <el-checkbox :label="item" v-for="(item, index) in tag_list" :key="index" class="tag"></el-checkbox>
            </el-checkbox-group>
        </div>
        <div>
            <span class="left-desc">是否发布</span>
            <el-radio v-model="is_publish_label" label="是">是</el-radio>
            <el-radio v-model="is_publish_label" label="否">否</el-radio>
        </div>
        <div>
            <span class="left-desc">是否展示</span>
            <el-radio v-model="is_show_label" label="是">是</el-radio>
            <el-radio v-model="is_show_label" label="否">否</el-radio>
        </div>
        <div>
            <span class="left-desc">是否原创</span>
            <el-radio v-model="is_original_label" label="是">是</el-radio>
            <el-radio v-model="is_original_label" label="否">否</el-radio>
        </div>
        <div v-if="is_original_label==='否'">
            <span class="left-desc">原作者</span>
            <el-input v-model="author" placeholder="请输入原作者" style="width: 20%;"></el-input>
        </div>
        <div>
            <span class="left-desc">是否允许评论</span>
            <el-radio v-model="allow_comment_label" label="是">是</el-radio>
            <el-radio v-model="allow_comment_label" label="否">否</el-radio>
        </div>
        <div class="submit">
            <el-button type="primary" round @click="uploadArticle()">发布</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            cover_img: '',
            cover_path: '',
            cate: '学习',
            cate_list: ['学习', '随笔', '收藏', '游记'],
            tag_list: [],
            checkList: [],
            title: '',
            desc: '',
            content: '',
            author: '',
            is_publish_label: '是',
            is_show_label: '是',
            is_original_label: '是',
            allow_comment_label: '是',
        }
    },
    mounted(){
        this.getTags();
        if (this.$route.query.article_id){
            // 发请求获取文章数据
            this.getArticle(this.$route.query.article_id);
        }
    },
    methods: {
        handleAvatarSuccess(res) {
            this.cover_path = res.path;
            this.cover_img = this.$imgUrl + res.path;
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传封面图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传封面图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        async getTags() {
            const res = await this.$http.get('articles/tags/');
            this.tag_list = res.data.data;
        },
        async uploadArticle(){
            const data = {
                'title': this.title,
                'desc': this.desc,
                // 'cover_img': this.cover_img,
                'cover_img': this.cover_path,
                'content': this.content,
                'cate': this.cate,
                'checkList': this.checkList,
                'is_publish_label': this.is_publish_label,
                'is_show_label': this.is_show_label,
                'is_original_label': this.is_original_label,
                'allow_comment_label': this.allow_comment_label,
                'author': this.author,
                // 更新标记
                'article_id': this.$route.query.article_id ? this.$route.query.article_id : ''
            }
            if(data.title && data.content){
                await this.$http({
                    url: 'articles/upload_article/',
                    method: 'post',
                    data: data
                })
                .then((res) => {
                    const data = res.data;
                    if(data.code===200){
                        alert(data.msg);
                        location.reload();
                    }else{
                        alert(data.msg);
                    }
                })
                .catch((err) => {
                    alert('发布文章失败,reason:' + err)
                });
            }else{
                alert('请先完成文章编辑');
            } 
        },
        async $imgAdd(pos, $file){
            // 第一步.将图片上传到服务器
            var formdata = new FormData();
            formdata.append('file', $file);
            await this.$http({
               url: 'articles/upload_img/',
               method: 'post',
               data: formdata,
               headers: { 'Content-Type': 'multipart/form-data' },
            }).then(res => {
               // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
               if (res.data.code === 200){
                   this.$refs.md.$img2Url(pos, this.$imgUrl + res.data.path);
               }else{
                   alert(res.data.msg);
               }
            }).catch(err => {
                alert(err)
            })
        },
        // 更新文章
        async getArticle(id) {
            const res = await this.$http.get(`articles/detail/?id=${id}`);
            if(res.data.code !== 200){
                alert(res.data.msg);
            }else{
                const article_info = res.data.article_info;
                this.title = article_info.title;
                this.desc = article_info.desc;
                if(article_info.cover_img){
                    this.cover_img = this.$imgUrl + article_info.cover_img;
                }
                this.content = article_info.content;
                this.cate = article_info.category;
                this.checkList = article_info.tags;
                if(article_info.is_original) {
                    this.is_original_label = '是'
                }else{
                    this.is_original_label = '否'
                }
                if(article_info.is_publish) {
                    this.is_publish_label = '是'
                }else{
                    this.is_publish_label = '否'
                }
                if(article_info.is_show) {
                    this.is_show_label = '是'
                }else{
                    this.is_show_label = '否'
                }
                if(article_info.allow_comment) {
                    this.allow_comment_label = '是'
                }else{
                    this.allow_comment_label = '否'
                }
                this.author = article_info.author;
            }
        },
    }
}
</script>

<style>
#mavon div .left-desc {
    display: inline-block;
    padding: 8px 20px 8px 0;
    font-weight: bolder;
}
#mavon .submit {
    text-align: center;
}
#mavon .submit button {
    min-width: 200px;
    font-size: 16px;
    margin: 20px 0;
}
/* 文章封面图片 */
#mavon .avatar-wrap {
    position: relative;
}
#mavon .avatar-wrap .avatar-uploader {
    /* padding: 20px 20px; */
    border: 1px solid grey;
    /* width: 10%; */
    width: 200px;
    height: 200px;
    text-align: center;
    line-height: 200px;
}
#mavon .avatar-wrap .avatar {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 65px;
    left: 25px;
}
/* tags */
#mavon .tags .tags-wrap {
    display: flex;
}
#mavon .tags .tags-wrap {
    display: flex;
    flex-wrap: wrap;
}
#mavon .tags .tags-wrap .tag {
    padding: 5px 5px;
}
</style>
