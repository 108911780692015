<template>
    <div id="coins">
        <el-button type="primary" id="enter-coin" @click="toEnterCoin()">录入钱币</el-button>
        <h3>汇总记录</h3>
        <div id="sum">
            <div>
                <span>总币数</span>
                <span>{{sum_coins}}</span>
            </div>
            <div>
                <span>总花销</span>
                <span>{{sum_cost}}</span>
            </div>
            <div>
                <span>卖出币数</span>
                <span>{{sum_sold_num}}</span>
            </div>
            <div>
                <span>经营所得</span>
                <span>{{sum_sold_money}}</span>
            </div>
        </div>
        <h3>查询条件</h3>
        <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="80px" class="demo-ruleForm">
            <el-form-item label="钱币名称" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="是否售出" prop="is_sold">
                <el-switch v-model="ruleForm.is_sold"></el-switch>
            </el-form-item>
            <el-form-item label="起始日期" prop="start_date">
                <el-date-picker
                        type="date"
                        placeholder="起始日期"
                        v-model="ruleForm.start_date"
                        value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期" prop="end_date">
                <el-date-picker
                        type="date"
                        placeholder="结束日期"
                        v-model="ruleForm.end_date"
                        value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <!-- 排序规则 -->
            <el-form-item label="排序规则" prop="order">
                <el-select v-model="ruleForm.order" placeholder="请选择排序规则">
                    <el-option label="按照购买时间倒序" value="date"></el-option>
                    <el-option label="按照购买价格倒序" value="price"></el-option>
                </el-select>
            </el-form-item>
            <!-- 查询按钮 -->
            <el-button type="primary" id="submit" @click="submitQuery()">查询</el-button>
            <!-- 重置按钮 -->
            <!-- prop属性很重要，能够影响到校验rule和重置是否生效:不设置prop的话，重置不会生效 -->
            <el-button type="primary" id="reset" @click="resetForm('ruleForm')">重置</el-button>
        </el-form>
        <h3>购买记录</h3>
        <el-table
                :data="coins_info"
                @row-click="toCoinDetail"
                border>
            <el-table-column
                    prop="id"
                    label="钱币编号"
                    width="40">
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="钱币名称">
            </el-table-column>
            <el-table-column
                    prop="buy_time"
                    label="购买日期">
            </el-table-column>
            <el-table-column
                    prop="buy_cost"
                    label="购买价格">
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paginator">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    :pager-count=page_count
                    :page-size=limit
                    :total=total
                    :current-page.sync=page
                    @current-change="currentChange()"
            >
            </el-pagination>
        </div>
        <!-- 返回按钮 -->
        <div class="iconfont icon-fanhui" @click="$router.back(-1);"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sum_coins: 0,
                sum_cost: 0,
                sum_sold_num: 0,
                sum_sold_money: 0,
                ruleForm: {
                    'name': '',
                    'is_sold': false,
                    'start_date': '',
                    'end_date': '',
                    'order': ''
                },
                // 控制到第几页时分页器就折叠
                page_count: 5,
                // 控制每页展示条数
                limit: 5,
                // 当前页
                page: 1,
                total: 0,
                coins_info: []
            }
        },
        mounted() {
            this.summaryCoinsInfo();
            this.submitQuery();
        },
        methods: {
            async submitQuery() {
                const res = await this.$http.post(`coins/coins_info/?page=${this.page}&limit=${this.limit}`, {
                    'ruleForm': this.ruleForm
                });
                this.total = res.data.total;
                this.coins_info = res.data.data;
            },
            async summaryCoinsInfo() {
                const res = await this.$http.get(`coins/coins_info/summary/`);
                if (res.data.code == 200) {
                    const data = res.data.data;
                    this.sum_coins = data.total;
                    this.sum_cost = data.total_price;
                    this.sum_sold_num = data.sold_total;
                    this.sum_sold_money = data.sold_total_price;
                } else {
                    alert(res.data.msg);
                }
            },
            toEnterCoin() {
                this.$router.push('/i/enter_coin');
            },
            toCoinDetail(row) {
                this.$router.push(`/i/coin/${row.id}`);
            },
            currentChange() {
                this.submitQuery(this.limit, this.page);
            },
            resetForm(formName) {
                // 重置表单
                this.$refs[formName].resetFields();
            }
        }
    }
</script>

<style>
    #coins h3,#enter-coin {
        margin: 10px 0;
    }
    #coins #sum div {
        display: inline-block;
        width: 45%;
        padding: 10px;
    }
    #coins #sum div span {
        margin-right: 5px;
    }
    #coins .paginator {
        margin-top: 8px;
        text-align: right;
    }
    #coins {
        position: absolute;
    }
    #coins .icon-fanhui {
        position: absolute;
        right: 40px;
        top: 20px;
        font-size: 25px;
    }
</style>