import Vue from 'vue'
import App from './App.vue'

// 導入全局樣式
import '@/assets/style/global.css'
// 导入router并添加
import router from '@/router/index'

// 定義一個全局的axios對象，並設置默認後端
import axios from 'axios'
// 綫下
// axios.defaults.baseURL = 'http://127.0.0.1:8000/'
// 綫上
axios.defaults.baseURL = 'https://sanbai.org.cn:8000/'
axios.defaults.withCredentials = true
Vue.prototype.$http = axios

// 集成vue-cookie
import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

// 集成mavon
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

// 集成element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 集成全局过滤器
import * as filters from '@/common/filter'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 定义图片上传文件夹
Vue.prototype.$imgUrl = 'http://img.sanbai.org.cn/'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
