<template>
    <div id="topshow">
        <div id="articles">
            <el-timeline>
                <el-timeline-item v-for="(item,index) in data_list" :key="index" :timestamp="item.create_time"
                                  placement="top">
                    <el-card id="item">
                        <h3 @click="toArticleDetail(item.id)">{{ item.title }}</h3>
                        <p>{{ item.desc }}</p>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </div>

        <!-- 开发右边侧边栏 -->
        <div id="right-part">
            <div id="articles-classify-wrap">
                <div id="articles-classify-title">三白手记系列</div>
                <div v-for="(item, index) in classify_list" :key="index">
                    <div class="articles-classify" @click="classifyToArticleList(item.class_name)">{{ item.class_name }}</div>
                </div>
                <div id="articles-classify-pg">
                    <el-pagination
                            layout="prev, pager, next"
                           :page-size="classify_limit"
                           :total="classify_total"
                           :current-page.sync="classify_page"
                           @current-change="classifyCurrentChange()"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
   data(){
       return {
           data_list: [],
           classify_list: [],
           classify_limit: 10,
           classify_page: 1,
           classify_total: 0
        }
   },
   mounted(){
       this.getArticles();
       this.getArticleClassify(this.classify_page, this.classify_limit);
   },
   methods: {
       async getArticles(){
           const res = await this.$http.get('articles/top_show/');
           this.data_list = res.data.data;
       },
       // 获取文章三白手记系列分类
       async getArticleClassify(page, limit){
           const res = await this.$http.get(`articles/classify/?limit=${limit}&page=${page}`);
           this.classify_list = res.data.data;
           this.classify_total = res.data.total;
       },
       toArticleDetail(id) {
           this.$router.push(`/article/${id}`);
       },
       // 文章 三白手记系列分类 分页
       classifyCurrentChange() {
           this.getArticleClassify(this.classify_page, this.classify_limit);
       },
       // 点击三百手记分类跳转
       classifyToArticleList(class_name) {
           this.$router.push(`/articles?category=search&search_data=${class_name}`);
       }
   },
}
</script>

<style>
    #topshow {
        display: flex;
    }
    #topshow #articles {
        width: 70%;
    }
    #topshow #articles #item h3 {
        cursor: pointer;
        font-size: 20px;
    }

    /* 侧边栏功能区样式 */
    #topshow #right-part {
        margin-left: 60px;
        width: 20%;
    }
    #topshow #right-part #articles-classify-wrap #articles-classify-title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 15px
    }
    #topshow #right-part #articles-classify-wrap .articles-classify {
        font-size: 18px;
        margin: 0 0 10px 10px;
        cursor: pointer;
    }
    #topshow #right-part #articles-classify-wrap .articles-classify:hover {
        font-size: 20px;
    }
    #topshow #right-part #articles-classify-wrap #articles-classify-pg div.el-pagination {
        margin-left: 50px;
    }
    #topshow #right-part #articles-classify-wrap #articles-classify-pg div.el-pagination * {
        background-color: rgb(241, 242, 249);
        color: black;
        cursor: pointer;
    }
</style>