<template>
  <div id="app">
    <div class="app-nav">
      <div class="avtor-wrap">
        <img src="@/assets/img/avtor.png" alt="avtor" @click="toPersonal">
      </div>
      <div>三白个人小站</div>
      <div class="connect">
        <a href="https://gitee.com/sanbai-zhang">
          <span class="iconfont icon-GitHub"></span>
        </a>
      </div>
      <div class="nav-li">
        <div @click="toTopShow()">TopShow</div>
        <div @click="toLearn()">学习</div>
        <div @click="toEssay()">随笔</div>
        <div @click="toCollection()">收藏</div>
        <div @click="toTravel()">游记</div>
      </div>
      <div class="search">
        <input v-model="search_data" type="text" placeholder="搜索" @keyup.enter='toSearchList(search_data)'>
      </div>
    </div>
    <!-- 内容输出区域 -->
    <div class="app-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      search_data: ''
    }
  },
  mounted(){
    this.delLeftNav();
  },
  methods: {
    toPersonal(){
      if(this.$cookie.get('name') !== null) {
        this.$router.push('/personal');
      }else{
        this.$router.push('/login');
      }
    },
    toTopShow() {
      this.$router.push('/topshow');
    },
    toSearchList(value) {
      this.$router.push(`/articles?category=search&search_data=${value}`);
    },
    toLearn() {
      this.$router.push(`/learn?category=learn`);
    },
    toEssay() {
      this.$router.push(`/essay?category=essay`);
    },
    toCollection() {
      this.$router.push(`/collection?category=collection`);
    },
    toTravel() {
      this.$router.push(`/travel?category=travel`);
    },
    // 手机端页面干掉侧边栏
    delLeftNav() {
        if(this.$route.path.startsWith('/i/')){
            document.getElementsByClassName('app-nav')[0].style.display='none'; 
            document.getElementsByClassName('app-content')[0].style.width='100%'; 
            document.getElementById('app').style.padding = '20px 20px 20px 0';
        }
    },
  }
}
</script>

<style>
#app {
  background-color: rgb(241, 242, 249);
  width: 100%;
  height: 100%;  
  min-height: 100vh;
  padding: 60px 45px;
  position: relative;
  display: flex;
}
/* 导航区样式 */
#app .app-nav {
  width: 15%;
  height: 85%;
  border-radius: 10px;
  background-color: white;
  max-height: 500px;
}
#app .app-nav {
  text-align: center;
}
#app .app-nav .avtor-wrap img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 20px 0;
  cursor: pointer;
}
#app .app-nav .connect {
  margin: 20px 0;
}
#app .app-nav .connect .icon-GitHub {
  font-size: 20px;
  cursor: pointer;
}
#app .app-nav .nav-li {
  margin-top: 20px;
}
#app .app-nav .nav-li div {
  padding: 12px 0;
  cursor: pointer;
}
#app .app-nav .nav-li div:hover {
  background-color: rgb(68, 206, 246);
  color: white;
}
#app .app-nav .search input {
  text-align: center;
  border: none;
  padding: 12px 0;
  vertical-align: middle;
}
#app .app-nav .search input:focus {
  outline: none
}
/* 主体内容区样式 */
#app .app-content {
  padding-left: 20px;
  width: 85%;
}
</style>
