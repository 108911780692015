<template>
    <div id="comment-wrap">
        <!-- 发表评论 -->
        <div class="submit">
            <el-input 
                class="comment"
                rows=2
                type="textarea"
                placeholder="本网站不接受他人注册,所以评论功能仅为练手,如有问题需要探讨，欢迎通过左侧联系方式添加本人,ps: 所有问题我都会回复哒" 
                v-model="comment">
            </el-input>
            <el-button type="primary" plain class="btn" @click="submitComment()">发表评论</el-button>
        </div>   
        <div class="choice">
            <span id='is-show'>评论是否展示</span>
            <el-radio v-model="is_show_label" label="是">是</el-radio>
            <el-radio v-model="is_show_label" label="否">否</el-radio>
        </div> 
        <!-- 评论列表 -->
        <div class="show">
            <h3>评论列表</h3>
            <div class="items" v-for="item in comment_list" :key="item.id">
                <div class="show-content">{{item.content}}</div>
                <span class="show-create-time">{{item.create_time | getLocalDatetime}}</span>
                <span class="show-reply-num">
                    <span @click="displayReply($event)">回复</span>
                </span>
                <span id="reply-list" @click="displayReplyList($event)">展开{{item.child.length}}条回复</span>
                <div class="reply-wrap">
                    <div class="child-items" v-for="child_item in item.child" :key="child_item.id">
                        <div class="show-content">{{child_item.content}}</div>
                        <div class="p-comment">@:{{child_item.p_content}}</div>
                        <div class="show-reply-num">
                            <span>{{child_item.create_time | getLocalDatetime}}</span>
                            <span @click="displayInnerReply($event)">回复</span>
                             <!-- 回复中点击回复跳出弹框 -->
                            <div class="submit-reply">
                                <el-input 
                                    class="reply"
                                    rows=2
                                    type="textarea"
                                    placeholder="请输入回复" 
                                    v-model="reply">
                                </el-input>
                                <br>
                                <div class="choice">
                                    <span id='is-show'>回复是否展示</span>
                                    <el-radio v-model="reply_show_label" label="是">是</el-radio>
                                    <el-radio v-model="reply_show_label" label="否">否</el-radio>
                                </div> 
                                <el-button type="primary" plain class="btn" @click="submitReply(child_item.id)">提交</el-button>
                            </div>
                        </div> 
                    </div>
                </div>
                <!-- 回复一级评论 -->
                <div class="reply-oneth">
                    <el-input 
                        class="reply"
                        rows=2
                        type="textarea"
                        placeholder="请输入回复" 
                        v-model="reply">
                    </el-input>
                    <br>
                    <div class="choice">
                        <span id='is-show'>回复是否展示</span>
                        <el-radio v-model="reply_show_label" label="是">是</el-radio>
                        <el-radio v-model="reply_show_label" label="否">否</el-radio>
                    </div> 
                    <el-button type="primary" plain class="btn" @click="submitReply(item.id)">提交</el-button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    data(){
        return {
            comment: '',
            reply: '',
            is_show_label: '是',
            reply_show_label: '是',
            comment_list: []
        }
    },
    mounted(){
        this.getCommentList(this.$route.params.id);
    },
    methods: {
        async getCommentList(id) {
            const res = await this.$http.get(`articles/comments/?article_id=${id}`);
            if (res.data.code === 200){
                this.comment_list = res.data.data;
            }else{
                alert(res.data.msg)
            }
        },
        displayReply(e) {
            // 回复中点击回复跳出弹框
            const reply_oneth = e.currentTarget.parentElement.nextElementSibling.nextElementSibling.nextElementSibling;
            if(reply_oneth.style.display==='block') {
                reply_oneth.style.display = 'none'
            }else {
                reply_oneth.style.display = 'block'
            }
        },
        displayReplyList(e) {
            // 按元素位置找到当前点击元素这一块中的对应的回复div
            // e.currentTarget是绑定当前点击事件的元素
            // e.target是当前点击的元素
            // 尽量用 e.currentTarget
            const reply_wrap = e.currentTarget.nextElementSibling;
            if(reply_wrap.style.display==='block') {
                reply_wrap.style.display = 'none'
            }else {
                reply_wrap.style.display = 'block'
            }
        },
        displayInnerReply(e) {
            // 回复中点击回复跳出弹框
            const submit_reply = e.currentTarget.nextElementSibling;
            if(submit_reply.style.display==='block') {
                submit_reply.style.display = 'none'
            }else {
                submit_reply.style.display = 'block'
            }
        },
        async submitComment() {
            const data = {
                'article_id': this.$route.params.id,
                'content': this.comment,
                'is_show_label': this.is_show_label
            }
            const res = await this.$http.post(`articles/comments/`, data);
            alert(res.data.msg);
            location.reload();
        },
        async submitReply(p_id) {
            const data = {
                'article_id': this.$route.params.id,
                'content': this.reply,
                'is_show_label': this.reply_show_label,
                'p_id': p_id
            }
            const res = await this.$http.post(`articles/comments/`, data);
            alert(res.data.msg);
            location.reload();
        },
    }
}
</script>

<style>
/* 发表评论 */
#comment-wrap .submit {
    display: flex;
    align-items: center;
}
#comment-wrap .submit .comment {
    width: 90%;
    padding: 10px 10px;
    font-size: 16px;
    margin: 0;
}
#comment-wrap .submit .btn {
    margin: 0;
    padding: 10px 10px;
}
#comment-wrap .choice {
    padding: 10px 10px;
    margin-bottom: 10px;
}
#comment-wrap .choice #is-show {
    margin-right: 20px
}
/* 评论列表 */
#comment-wrap .show {
    padding: 10px 10px;
}
#comment-wrap .show h3 {
    margin-bottom: 10px;
}
#comment-wrap .show .items {
    background-color: skyblue;
    padding: 0 20px 10px 0;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
}
#comment-wrap .show .items .show-reply-num,
#comment-wrap .show .items #reply-list{
    cursor: pointer;
}
#comment-wrap .show .p-comment {
    background-color: lightgrey;
    margin: 5px 0;
}
#comment-wrap .show .reply-wrap {
    margin-left: 20px;
    display: none;
}
#comment-wrap .show .reply-wrap .child-items {
    background-color: white; 
    margin-bottom: 10px;
    border-radius: 10px;
}
#comment-wrap .show .reply-wrap .child-items:first-child {
    margin-top: 10px;
}
#comment-wrap .show .show-content,
#comment-wrap .show .p-comment,
#comment-wrap .show .show-create-time,
#comment-wrap .show .show-reply-num {
    padding: 5px 10px;
}
#comment-wrap .show .reply-wrap .show-reply-num span {
    margin-right: 10px;
}
/* 回复中点击回复跳出弹框 */
#comment-wrap .show .reply-wrap .submit-reply {
    position: absolute;
    top: 35px;
    left: 175px;
    z-index: 1;
    background-color: lightslategrey;
    padding: 10px 10px;
    border-radius: 5px;
    display: none;
}
#comment-wrap .show .reply-wrap .show-reply-num {
    position: relative;
}
#comment-wrap .show .reply-wrap .submit-reply .reply {
    width: 400px;
}
/* 回复一级评论 */
#comment-wrap .show .items .reply-oneth {
    position: absolute;
    top: 65px;
    left: 185px;
    z-index: 1;
    background-color: lightslategrey;
    padding: 10px 10px;
    border-radius: 5px;
    display: none;
}
#comment-wrap .show .items .reply-oneth .reply {
    width: 400px;
}
</style>