<template>
    <div id="personal">
        <div class="top-nav">
            <div @click="toPublishAirticle" class="nav-wrap">
                <div class="iconfont icon-houtaiguanli-fabuwenzhang"></div>
                <div class="nav-title">文章发布</div>
            </div>
            <div @click="toManageAirticle" class="nav-wrap">
                <div class="iconfont icon-guanliwenzhang"></div>
                <div class="nav-title">文章管理</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            cat: '文章发布'
        }
    },
    methods: {
        toPublishAirticle(){
            this.$router.push('/mavon');
        },
        toManageAirticle(){

        },
    }
}
</script>

<style>
#personal {
    height: 100%;
    min-height: 100vh;
}
#personal .top-nav {
    display: flex;
    width: 100%;
    text-align: center;
    flex-wrap: wrap;
}
#personal .top-nav .nav-wrap {
    padding: 20px 20px;
    margin-right: 20px;
    cursor: pointer;
    min-width: 104px;
    min-height: 114px;
}
#personal .top-nav .iconfont {
    font-size: 40px;
    margin-bottom: 10px;
}
#personal .top-nav div:hover {
    background-color: white;
    border-radius: 10px;
}
</style>