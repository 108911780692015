<template>
    <div id="article-detail">
        <div class="title">{{article_info.title}}</div>
        <div class="desc">{{article_info.desc}}</div>
        <div class="info-wrap">
            <span class="info original" v-show="article_info.is_original">原创</span>
            <span class="info author">{{article_info.author}}</span>
            <span class="info create_time">{{article_info.create_time | getLocalDatetime}}</span>
            <span class="info view">
                <span class="iconfont icon-liulanliang"></span>
                <span>{{article_info.pageviews}}</span>
            </span>
            <span class="info">
                <label for="">分类专栏：</label>
                <span class="tag">{{article_info.category}}</span>
            </span>
            <span class="info" v-if="tags_len!==0">
                <label for="">文章标签：</label>
                <span class="tag" v-for="(item, index) in article_info.tags" :key="index">{{item}}</span>
            </span>
        </div>
        <mavon-editor class="content" v-html="article_info.content"></mavon-editor>
        <comment v-if="article_info.allow_comment"></comment>
        <span class="iconfont icon-15" @click="editArticle()"></span>
    </div>
</template>

<script>
import marked from 'marked'
import comment from './comment.vue'

export default {
    components: {
        comment
    },
    data(){
        return {
            article_info: '',
            tags_len: ''
        }
    },
    mounted(){
        this.getArticle(this.$route.params.id);
    },
    methods: {
        async getArticle(id) {
            const res = await this.$http.get(`articles/detail/?id=${id}`);
            if(res.data.code !== 200){
                alert(res.data.msg);
            }else{
                this.article_info = res.data.article_info;
                this.article_info.content = marked(this.article_info.content);
                this.tags_len = this.article_info.tags.length;
            }
        },
        editArticle() {
            if(this.$cookie.get('name') !== null) {
                this.$router.push(`/mavon?article_id=${this.$route.params.id}`);
            }else{
                this.$router.push('/login');
            }
        },
    }
}
</script>

<style>
#article-detail .title,
#article-detail .desc,
#article-detail .info-wrap,
#article-detail .content {
    margin: 20px 0;
}
#article-detail .title {
    font-size: 22px;
    font-weight: bolder;
}
#article-detail .info-wrap {
    display: flex;
    flex-wrap: wrap;
    background: white;
    border-radius: 10px;
    padding: 10px 10px;
    color: grey;
}
#article-detail .info-wrap .info {
    display: inline-block;
    padding: 5px 10px;
    margin: 5px 5px;
}
#article-detail .info-wrap .original {
    background: rgb(240, 174, 169);
    color: white;
    border-radius: 5px;
}
#article-detail .info-wrap .author {
    color: lightsalmon;
}
#article-detail .info-wrap .view .icon-liulanliang {
    margin-right: 5px;
}
#article-detail .info-wrap .tag {
    background: rgb(241, 242, 249);
    padding: 5px 5px;
    border-radius: 5px;
    font-size: 14px;
    color: rgb(26, 115, 232);
    margin: 5px 5px;
}
#article-detail .content {
    padding: 10px 10px;
}
/* 修改文章按钮 */
#article-detail {
    position: relative;
}
#article-detail .icon-15 {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
}
/* 控制图片大小 */
#article-detail .content img {
    max-width: 950px;
}
</style>
